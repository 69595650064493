*, *:before, *:after {
  box-sizing:inherit;
}
div {
  display:block;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}
html {
  font-family: 'Roboto', 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  overflow-x:hidden;
  box-sizing: border-box;
}
body, html {
  line-height:1.5;
  font-size:15px;
  -webkit-text-size-adjust: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.page-wrapper {
  max-width:1400px;
  margin-left:auto;
  margin-right:auto;
  margin-top:16px;
  position: relative;
  }
